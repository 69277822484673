var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","align-content":"center","align-items":"center"},attrs:{"cols":"7","lg":"10","md":"10","sm":"6"}},[_c('h3',[_vm._v("Lost Opportunities")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getRegisters($event)}}},[_c('v-text-field',{attrs:{"label":"Quick Search","prepend-inner-icon":"mdi mdi-magnify","outlined":"","single-line":"","dense":""},on:{"click:prepend-inner":_vm.getRegisters},model:{value:(_vm.filter.fastSearch),callback:function ($$v) {_vm.$set(_vm.filter, "fastSearch", $$v)},expression:"filter.fastSearch"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('label',[_vm._v("Sales Person")]),_c('v-combobox',{attrs:{"items":_vm.listSellers,"item-text":"name","item-value":"id","clearable":"","outlined":"","dense":""},on:{"change":function($event){return _vm.filterRegisters()}},model:{value:(_vm.filter.sellerSelected),callback:function ($$v) {_vm.$set(_vm.filter, "sellerSelected", $$v)},expression:"filter.sellerSelected"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredSearch,"options":_vm.options,"loading":_vm.loading,"items-per-page":10,"no-data-text":"No Record Found"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.idServiceFormatted",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({on:{"click":function($event){return _vm.goToServiceDetails(item.idService)}}},on),[_vm._v(" "+_vm._s(item.idServiceFormatted)+" ")])]}}],null,true)},[_c('span',[_vm._v("Job Specifics")])])]}},{key:"item.statusDescription",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.statusDescription)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ActionList',{attrs:{"id":item.id,"showButtons":{
                            addClientConsideration: _vm.userLoggedGetters.profile.inputClientConsiderationsUnclosedDeals == 1
                        }},on:{"confirmAddClientConsideration":function($event){return _vm.showDialogClientConsideration(item.id)}}})]}}])})],1)],1),_c('DialogUnclosedDeal',{attrs:{"idAux":_vm.dialogUnclosedDeal.idAux,"id":_vm.dialogUnclosedDeal.id,"idService":_vm.dialogUnclosedDeal.idService,"showDialogUnclosedDeal":_vm.dialogUnclosedDeal.show},on:{"methodConfirmToCall":_vm.dialogUnclosedDeal.methodConfirm,"update:showDialogUnclosedDeal":function($event){return _vm.$set(_vm.dialogUnclosedDeal, "show", $event)},"update:show-dialog-unclosed-deal":function($event){return _vm.$set(_vm.dialogUnclosedDeal, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="7"
                lg="10"
                md="10"
                sm="6"
            >
                <h3>Lost Opportunities</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <label>Sales Person</label>
                <v-combobox
                    v-model="filter.sellerSelected"
                    :items="listSellers" 
                    item-text="name"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    <template v-slot:item.idServiceFormatted="{ item }">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <a @click="goToServiceDetails(item.idService)" v-on="on">
                                    {{ item.idServiceFormatted }}
                                </a>
                            </template>
                            <span>Job Specifics</span>
                        </v-tooltip>
                    </template>
                    
                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>
                    
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id"
                            :showButtons="{
                                addClientConsideration: userLoggedGetters.profile.inputClientConsiderationsUnclosedDeals == 1
                            }"
                            @confirmAddClientConsideration="showDialogClientConsideration(item.id)"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <DialogUnclosedDeal
            :idAux="dialogUnclosedDeal.idAux"
            :id="dialogUnclosedDeal.id"
            :idService="dialogUnclosedDeal.idService"
            v-on:methodConfirmToCall="dialogUnclosedDeal.methodConfirm"
            :showDialogUnclosedDeal.sync="dialogUnclosedDeal.show"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import DialogUnclosedDeal from "@/components/Services/DialogUnclosedDeal";
    export default ({

        components: {
            ActionList,
            DialogUnclosedDeal
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

            filter: {
                fastSearch: '',
                customerOriginSelected: null,
                sellerSelected: null,
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Service ID", value: "idServiceFormatted", sortable: true },
                { text: "Closed On", value: "dateRegisterFormatted", sortable: true },
                { text: "Closed By", value: "userNameRegister", sortable: true },
                { text: "Customer", value: "customerName", sortable: true },
                { text: "Sales Person", value: "salesPersonName", sortable: true },
                { text: "Reason", value: "reasonUnclosedDealDescription", sortable: true },
                { text: "Description", value: "description", sortable: true }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listServiceUnclosedDeal: [],
            listServiceUnclosedDealFiltered: [],
            listSellers: [],

            dialogUnclosedDeal: {
                idAux: 0,
                id: 0,
                idService: 0,
                show: false,
                methodConfirm: ( () => {} ),
            },
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            filteredSearch: function() {
                return this.listServiceUnclosedDealFiltered.filter((serviceUnclosedDealFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var idServiceFormatted = serviceUnclosedDealFilter.idServiceFormatted != null && serviceUnclosedDealFilter.idServiceFormatted != undefined ? serviceUnclosedDealFilter.idServiceFormatted.toLowerCase().match(filter) : ""
                    var dateRegisterFormatted = serviceUnclosedDealFilter.dateRegisterFormatted != null && serviceUnclosedDealFilter.dateRegisterFormatted != undefined ? serviceUnclosedDealFilter.dateRegisterFormatted.toLowerCase().match(filter) : ""
                    var userNameRegister = serviceUnclosedDealFilter.userNameRegister != null && serviceUnclosedDealFilter.userNameRegister != undefined ? serviceUnclosedDealFilter.userNameRegister.toLowerCase().match(filter) : ""
                    var customerName = serviceUnclosedDealFilter.customerName != null && serviceUnclosedDealFilter.customerName != undefined ? serviceUnclosedDealFilter.customerName.toLowerCase().match(filter): ""
                    var salesPersonName = serviceUnclosedDealFilter.salesPersonName != null && serviceUnclosedDealFilter.salesPersonName != undefined ? serviceUnclosedDealFilter.salesPersonName.toLowerCase().match(filter) : ""
                    var reasonUnclosedDealDescription = serviceUnclosedDealFilter.reasonUnclosedDealDescription != null && serviceUnclosedDealFilter.reasonUnclosedDealDescription != undefined ? serviceUnclosedDealFilter.reasonUnclosedDealDescription.toLowerCase().match(filter): ""
                    var description = serviceUnclosedDealFilter.description != null && serviceUnclosedDealFilter.description != undefined ? serviceUnclosedDealFilter.description.toLowerCase().match(filter) : ""
                    var id = serviceUnclosedDealFilter.id.toString().match(filter);

                    if (idServiceFormatted != null) { return idServiceFormatted; } 
                    else if(dateRegisterFormatted != null) { return dateRegisterFormatted; }
                    else if(userNameRegister != null) { return userNameRegister; } 
                    else if(customerName != null) { return customerName; } 
                    else if(salesPersonName != null) { return salesPersonName; }
                    else if(reasonUnclosedDealDescription != null) { return reasonUnclosedDealDescription; } 
                    else if(description != null) { return description; } 
                    else { return id; }
                });
            }
        },

        methods: {

            goToServiceDetails(id) {
                this.$router.push({ name: "serviceDetails", params: { id: id } });
            },

            async getRegisters() {

                this.listServiceUnclosedDeal = await this.$store.dispatch("serviceModule/ListUnclosedDeal");
                this.listServiceUnclosedDealFiltered = [...this.listServiceUnclosedDeal];
                this.listSellers = await this.$store.dispatch("userModule/ListSalesPerson");
                this.filterRegisters();

                if (this.userLoggedGetters.profile.inputClientConsiderationsUnclosedDeals == 1) {
                    this.headers = [
                        { text: "ID", value: "id", sortable: true, size: "5%" },
                        { text: "Service ID", value: "idServiceFormatted", sortable: true },
                        { text: "Closed On", value: "dateRegisterFormatted", sortable: true },
                        { text: "Closed By", value: "userNameRegister", sortable: true },
                        { text: "Customer", value: "customerName", sortable: true },
                        { text: "Sales Person", value: "salesPersonName", sortable: true },
                        { text: "Reason", value: "reasonUnclosedDealDescription", sortable: true },
                        { text: "Description", value: "description", sortable: true },
                        { text: "Client Consideration", value: "descriptionComplement", sortable: true },
                        { text: "Actions", value: "action", sortable: false, align: "center" }
                    ]
                }
            },

            async filterRegisters() {

                let idSeller = 0;
                let listFiltered = [...this.listServiceUnclosedDeal];

                if (this.filter.sellerSelected != null && this.filter.sellerSelected != undefined) {
                    idSeller = this.filter.sellerSelected.id;
                    listFiltered = listFiltered.filter(ser => ser.idUserResponsible == idSeller);
                }

                this.listServiceUnclosedDealFiltered = listFiltered;
            },

            async showDialogClientConsideration(id) {
                this.dialogUnclosedDeal = {
                    idAux: Math.random(),
                    id,
                    idService: 0,
                    show: true,
                    methodConfirm: ( () => {  
                        this.getRegisters();
                    })
                }
            }
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>
